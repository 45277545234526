import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.object.values";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
import { sourceIndex, Totaldata } from '@/api/user_restPwd/Managesubordinates/department';
export default {
  components: {
    Head: Head,
    nxDataTabs: nxDataTabs
  },
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '来源分析',
      value1: '',
      customer_level: '',
      customer: [],
      //学员级别
      bumen: '',
      bumenoptions: [],
      //部门
      easyDataOption: {
        style: 'width:30%',
        span: 4,
        data: [{
          title: '公司客户总数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/1095ff91752325f2b5760858ab32bed1.jpg',
          key: 'icon-renshu'
        }, {
          title: '重点意向',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/7126855260e360f0d07aeec2367b21b6.jpg',
          key: 'icon-zhongdian'
        }, {
          title: '外送公海',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/4885d217e52f37acd8bfae188346fb30.jpg',
          key: 'icon-waisongdanweiguanli'
        }, {
          title: '成交数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2cbda790813b1017465cccf9a162afc6.jpg',
          key: 'icon-shichangchengjiaoshuju'
        }, {
          title: '跟进数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2bdf5eccfc829372f28edb8f90b605f4.jpg',
          key: 'icon-genzong'
        }, {
          title: '无效数据',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/de58e9576c4d4f63528e215ef15b8d9b.jpg',
          key: 'icon-wuxiao'
        }]
      },
      DiscountFigure: {
        columns: ['日期', '前台部门', '中台部门'],
        rows: []
      },
      tableData: [],
      start_time: 0,
      end_time: 0
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList();
      this.card();
    } else {}
  },
  methods: {
    GetList: function GetList() {
      var _this = this;
      sourceIndex({
        stime: this.start_time,
        etime: this.end_time
      }).then(function (respomse) {
        _this.tableData = Object.values(respomse.data.list_data);
        _this.DiscountFigure.rows = respomse.data.z_data.map(function (item, index) {
          return {
            日期: item.data_time,
            中台部门: item.sum_customer,
            前台部门: item.qsum_customer
          };
        });
      });
    },
    //卡片公共数据
    card: function card() {
      var _this2 = this;
      Totaldata().then(function (res) {
        _this2.easyDataOption.data.map(function (item, index) {
          item.count = Object.values(res.data)[index];
        });
      });
    },
    DataTime: function DataTime() {
      var _this3 = this;
      this.NumberEs = Number(0);
      if (this.value1 !== null) {
        var s_time = this.value1[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.value1[1].toString();
        this.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this3.GetList();
        });
      } else {
        this.$nextTick(function () {
          _this3.GetList();
        });
      }
    },
    handleChange: function handleChange() {}
  }
};