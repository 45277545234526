var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", { staticClass: "app-container" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { margin: "20px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "btn",
                    staticStyle: { margin: "10px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _vm._v(
                          "\n                    时间筛选:\n                    "
                        ),
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            type: "daterange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "timestamp",
                            size: "small",
                            "default-time": ["00:00:00", "23:59:59"],
                          },
                          on: { change: _vm.DataTime },
                          model: {
                            value: _vm.value1,
                            callback: function ($$v) {
                              _vm.value1 = $$v
                            },
                            expression: "value1",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                _c("nx-data-tabs", {
                  staticStyle: { margin: "10px 0px", "padding-left": "16%" },
                  attrs: { option: _vm.easyDataOption },
                }),
                _vm._v(" "),
                _c("ve-line", { attrs: { data: _vm.DiscountFigure } }),
                _vm._v(" "),
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.tableData,
                        width: "100%",
                        "row-key": "id",
                        border: "",
                        "tree-props": { children: "children" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "source_name",
                          label: "部门",
                          width: "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "sum_customer", label: "总数据" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "create_customer",
                          label: "当天的新建的数据",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "point_customer",
                          label: "重点意向的总数据",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "send_customer",
                          label: "外送公海的总数据",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "invalid_customer",
                          label: "无效数据总数据",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "follow_customer",
                          label: "跟进量总数据",
                        },
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }